.portfolio {
  max-width: 1200px;
  margin: 20px auto 50px;
  padding: 20px;
  text-align: center;
}

.portfolio h2 {
  margin-bottom: 20px;
}

.portfolio-carousel {
  position: relative;
}

.portfolio-item {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  margin: 10px;
}

.portfolio-item img {
  width: 100%;
  height: 170px;
  object-fit: cover;
}

.portfolio-item .portfolio-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.portfolio-item .portfolio-content h3 {
  margin: 10px 0;
}

.portfolio-item .portfolio-content p {
  margin-bottom: 10px;
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.portfolio-item .portfolio-content a {
  color: orange;
  text-decoration: none;
  font-weight: bold;
}
