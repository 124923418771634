* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
}

#navbar {
  background-color: orange;
  padding: 14px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-links {
  list-style: none;
  margin: 52px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  background-color: orange;
  width: 60%;
  height: 100vh;
  z-index: 1000;
  overflow-x: hidden;
  transition: transform 0.3s ease;
  transform: translateX(100%);
  text-transform: capitalize;
}

.navbar-links.active {
  transform: translateX(0);
}

.navbar-links li {
  padding: 13px;
  margin-top: 10px;
}

.navbar-links li a {
  color: white;
  text-decoration: none;
  margin-right: 1rem;
}

.navbar-toggle {
  display: block;
  cursor: pointer;
}

.bar {
  display: block;
  width: 24px;
  height: 2.5px;
  background-color: white;
  margin: 5.6px 0;
  margin-right: 20px;
  transition: transform 0.3s ease;
}

.logo img {
  height: 30px;
}

.close-toggle {
  color: white;
  width: 41px;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

body.menu-active {
  background: #fafafa10;
  backdrop-filter: blur(0.4rem);
  -webkit-backdrop-filter: blur(10px);
}

.bar.active:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
}

.bar.active:nth-child(2) {
  opacity: 0;
}

.bar.active:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

.background {
  background-color: black;
  opacity: .7;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 58px;
  left: 0;
  z-index: 1000;
}

@media (min-width:940px) {
  #navbar {
    padding: 10px 20px;
    color: white;
    height: 65px;
  }

  .navbar-container {
    height: 40px;
    text-align: center;
  }

  .navbar-links {
    width: 83%;
    color: white;
    flex-direction: row;
    transition: none;
    transform: none;
    z-index: 0;
    overflow-x: auto;
    position: relative;
    justify-content: right;
    height: min-content;
  }

  .navbar-links li {
    margin: 0;
    padding: 0;
    margin-right: 35px;
  }

  .navbar-links li:last-child {
    margin-right: 0;
  }

  .navbar-links li a {
    font-size: 18px;
  }

  .navbar-toggle {
    display: none;
  }

  .bar {
    width: 25px;
    height: 3px;
    margin: 4px 0;
  }

  .navbar-container .logo img {
    transform: scale(1.3);
  }
}

@media (min-width:991px) {

  .navbar-container .logo img {
    cursor: pointer;
  }

  .navbar-container {
    height: 40px;
  }

  .navbar-links {
    flex-direction: row;
    transition: none;
    transform: none;
    height: 30px;
    position: relative;
    text-align: center;
  }

  .navbar-links li a {
    color: white;
    font-size: 19px;
  }

  .navbar-toggle {
    display: none;
    cursor: pointer;
  }

  .navbar-container .navbar-links li a:hover {
    color: rgb(233, 230, 142);
  }
}

@media (min-width:1700px) {
  .navbar-links li {
    margin-right: 100px;
  }

  .navbar-links li a {
    font-size: 23px;
  }

  .navbar-links {
    height: 40px;
    padding: 5px;
  }

  .navbar-container .logo img {
    transform: scale(1.5);
  }
}

@media (min-width:2090px) {
  #navbar {
    height: 75px;
  }

  .navbar-container {
    height: 51px;
  }

  .navbar-links li {
    margin-right: 130px;
  }

  .navbar-links li a {
    font-size: 26px;
  }

  .navbar-links {
    height: 50px;
    padding: 8px;
  }

  .navbar-container .logo img {
    transform: scale(2);
  }

}