.form-container {
    text-align: center;
    margin-top: 1rem;
    width: 100%;
    color: white;
}

.form-container .form-contact .container{
    box-shadow: none;
    border: none;
    padding: 1.5rem;
    background-color: orange;
    width: 100%;
    margin: 0 auto;
}

.form-container .form-contact .form-spaces textarea {
    resize: none;
    border-radius: 5px;
    padding: 1px;
    border: none;
}

.form-container .form-contact {
    width: 100%;
    margin:  0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.form-container .form-contact .form-spaces {
    margin-bottom: 1.5rem;
    text-align:left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.form-container .form-contact .form-spaces input {
    margin-bottom: 0.6rem;
    text-align: center;
    height: 42px;
    border-radius: 5px;
    border: none;
    padding: 1px;
}

.form-container .form-contact .form-spaces input::placeholder {
    text-align: left;
}

.form-container .form-contact .form-spaces label {
    font-weight: 700;
}

.form-container .form-contact .form-spaces textarea {
    margin-bottom: 0.6rem;
    height: 82px;
    width: 198px;
}

.button-container .button-contact {
    background-color: #3d3d3d;
    border: none;
    width: 200px;
    height: 40px;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    z-index: 1000;
    position: relative;
}

.button-container .disable {
    cursor: not-allowed;
}

.title-form {
    margin-bottom: 2rem;
}

.title-form h4 {
    font-size: 30px;
    font-weight: 600;
}

@media (min-width: 550px) {
    .form-container .form-contact .container {
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: orange;
    }
}

@media (min-width: 576px) {
    .form-container {
        margin-bottom: 2rem;
    }

    .form-container .form-contact .container{
        border-radius: 8px;
    }
}

@media (min-width:700px) {
    .form-spaces label {
        display: block;
        margin-bottom: 0.2rem;
        font-size: 18px;
    }

    .form-container .form-contact .container{
        width: 750px;
    }

    .form-spaces input{
        width: 400px;
    }

    .form-container .form-contact .form-spaces textarea{
        width: 400px;
    }

    .title-form h4 {
        font-size: 35px;
    }

    .button-container .button-contact {
        width: 400px;
        height: 44px;
        margin-top: 1.4rem;
    }

    .form-container .form-contact .button-contact{
        cursor: pointer;
    }

    .button-container .disable {
        cursor: not-allowed;
    }
}

@media (min-width:1400px) {
    .form-container .form-contact {
        width: 500px;
    }

    .form-spaces input{
        width: 500px;
    }

    .form-container .form-contact .form-spaces textarea{
        width: 500px;
    }

    .form-spaces label {
        margin-bottom: 3rem;
        margin-top: 2rem;
    }

    .button-container .button-contact {
        width: 170px;
        height: 60px;
        margin-top: 2.4rem;
    }
}