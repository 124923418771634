.carousel {
  max-width: 1366px;
  margin: 0 auto;
}








