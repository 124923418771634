.footer {
    background-color: #3d3d3d;
    color: white;
}

.box-footer.desktop {
    display: none;
}

.footer .grupo-1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

.box-footer figure {
    justify-content: center;
    width: 100%;
    display: flex;
}

.box-footer#contact {
    z-index: 1000;
}

.box-footer {
    text-align: center;
}


.footer .grupo-1 .box-footer figure img {
    max-width: 100%;
    width: 10rem;
    transform: scale(2);
    padding-top: 3rem;
    padding-bottom: 2rem;
}


.footer .redes-sociales i {
    display: inline-block;
    text-decoration: none;
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin-right: 10px;
    text-align: center;
    transition: all 300ms ease;
    color: white;
    margin-top: 5px;
    font-size: 25px;
}

.redes-sociales a i:hover {
    color: orange;
}

.footer .grupo-2 {
    text-align: center;
    background-color: #3d3d3d;
    padding: 10px;
    padding-top: 2rem;
    padding-bottom: 4rem;
}

.box-footer h6, .box-footer p {
    display: none;
}

@media (min-width:560px) and (max-width:740px) {
    .footer {
        color: white;
    }

    .footer .grupo-1 {
        display: block;
        gap: 20px;
        padding: 20px;
    }

    .box-footer {
        text-align: center;
        justify-content: center;
        width: 100%;
    }

    .footer .grupo-1 .box-footer figure img {
        margin-bottom: 1rem;
    }

}

@media (min-width:741px) and (max-width:830px) {

    .footer {
        color: white;
    }

    .footer .grupo-1 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 20px;
        padding: 20px;
    }

    .footer .grupo-1 .box-footer figure img {
        max-width: 100%;
        width: 10rem;
        margin-right: 8.2rem;
    }

    .footer .redes-sociales a i {
        width: 25px;
        margin: 15px;
        font-size: 29px;
    }

    .box-footer#contact {
        margin-top: 5rem;
        margin-left: 3rem;
    }

    .footer .grupo-2 {
        padding: 10px;
        height: 90px;
        margin-top: -0.5rem;
    }

    .box-footer h6 {
        margin-bottom: 1rem;
        display:block ;
    }
}

@media (min-width:831px) {

    .footer {
        background-color: #3d3d3d;
        color: white;
    }

    .footer .grupo-1 .box-footer figure img {
        margin-left: -8rem;
    }

    .footer .redes-sociales a i {
        background-color: #3d3d3d;
        color: white;
        width: 25px;
        margin: 15px;
        font-size: 29px;
    }

    .box-footer#contact {
        margin-top: 5rem;
/*         margin-right: -3rem; */
        margin-left: 5.8rem;
    }

    .redes-sociales a i:hover {
        color: orange;
    }

    .footer .grupo-2 {
        text-align: center;
        background-color: #3d3d3d;
        padding: 10px;
        height: 85px;
    }

    .box-footer p {
        display: inline-block;
    }

    .box-footer h6 {
        margin-bottom: 1rem;
        display:block ;
    }

    .footer .redes-sociales a i:hover {
        color: orange;
    }
}

@media (min-width: 1000px) {
    .footer .grupo-1 {
        display: flex;
        align-items: center;
    }

    .box-footer.desktop {
        display: block;
    }
    .box-footer.desktop p {
        text-align: center;
        padding: 0 15px;
    }

    .box-footer {
        width: 33.3%;
    }

    .box-footer figure {
      display: block;
    }

    .footer .grupo-1 .box-footer figure img {
        margin-left: 0;
    }

    .box-footer#contact {
        margin-top: 0;
        margin-left: 0;
    }
}

@media (min-width:1680px) {
    .footer .redes-sociales a i {
        font-size: 32px;
    }
}