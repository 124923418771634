
.section-servicios {
  outline: none;
  border: none;
  text-decoration: none;
  transition: .2s linear;
  margin-top: 5rem;
  max-width: 100%;
}

.cards-servicios {
  background-color: white;
  padding: 15px 5%;
  margin: 0 auto;
  margin-top: -4rem;
}

.cards-servicios .titulo-servicios {
  text-align: center;
  padding-bottom: 10px;
  color: black;
  text-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  font-size: 28px;
}

.cards-servicios .card-servicios {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.box {
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  background-color: #fff;
  text-align: center;
  background-color: orange;
  width: calc(100% - 20px);
  max-width: 360px;
  height: 400px;
  margin: 10px 5px;
  border-radius: 10px;
}

.box img {
  height: 60px;
  margin: 15px 0;
}

.box h4 {
  color: white;
  font-size: 19px;
  width: 100%;
  padding: 9px 0px;
  text-transform: uppercase;
  background-color: #3d3d3d;
  min-height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box p {
  color: black;
  font-size: 16px;
  line-height: 1.7;
  margin: 1rem 10px 1rem;
  text-align: center;
  padding: 0 5px;
  font-weight: 500;
}

@media (max-width:360px) {
  .section-servicios .cards-servicios .card-servicios .box {
    height: max-content;
  }
}

@media (min-width:611px){

  .box h4 {
    font-size: 20px;
    padding: 10px 0px;
  }

}

@media (min-width: 701px) {

  .cards-servicios {
    padding: 15px 9%;
    margin: 0 auto;
    margin-top: -4rem;
  }

  .cards-servicios .titulo-servicios {
    font-size: 31px;
  }

  .cards-servicios .card-servicios {
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }

  .box {
    padding: 9px 0px;
    padding-bottom: 100px;
    width: 281px;
    height: 400px;
    margin: 20px 1px;
  }

  .box img {
    height: 80px;
  }

  .box h4 {
    font-size: 18px;
    padding: 10px 5px;
    margin-bottom: -1.1rem;
    height: 3.5rem;
  }

  .box p {
    font-size: 0.9rem;
    line-height: 1.7;
    margin-top: 1.8rem;
  }
}

@media (min-width: 1038px) {
  .section-servicios {
    max-width: 1366px;
    margin: 5rem auto 0;
  }

  .card-servicios {
    max-width: 1366px;
    width: 100%;
    padding-top: 10px;
  }
}

@media (min-width: 1366px) {
  .cards-servicios {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .cards-servicios .card-servicios {
    min-width: 1300px;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1367px) {
  .cards-servicios .card-servicios {
    min-width: 1366px;
    padding-bottom: 10px;
  }
}

@media (min-width:1610px) {
  .box {
    width: 281px;
    margin: 20px;
  }

  .box p {
    font-size: 15px;
    line-height: 1.6rem;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    text-align: center;
  }

  .box img {
    margin-top: 1px;
    margin-bottom: 6px;
  }
}

@media (min-width:1853px) {
  .cards-servicios .card-servicios {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .box {
    margin: 20px;
  }
}
