.instagram h2 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.instagram a {
  text-decoration: none;
}

.instagram-feed {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 16px;
}

.post {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  width: 90%;
  margin: 0 auto 20px;
  cursor: pointer;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post img,
.post video {
  width: 100%;
  height: 70%;
  object-fit: contain;
}

.post .post-header {
  display: flex;
  align-items: center;
  padding: 4px 16px;
  font-size: 14px;
  color: #000;
}

.post .post-header img {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-right: 12px;
}

.post .post-header .username {
  font-weight: bold;
}

.post .post-caption {
  padding: 16px;
}

.post .post-caption p {
  margin: 0;
  font-size: 14px;
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@media (min-width: 450px) {
  .post {
    width: 80%;
  }
}

@media (min-width: 600px) {
  .instagram-feed {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }

  .post {
    width: calc(50% - 16px);
    max-width: 640px;
  }
}

@media (min-width: 900px) {
  .post {
    width: calc(33.333% - 16px);
  }
}